import React from "react";
import DefaultLayout from "../layouts/default";
import { Link } from "gatsby";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import Title from "../components/Title";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import HorizontalWrapper from "../components/HorizontalWrapper";
import GridWrapper from "../components/GridWrapper";
import ProjekteGrid from "../components/ProjekteGrid";
import ProjektItem from "../components/ProjektItem";
import Wrapper from "../components/Wrapper";
import Button from "../components/Button";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

interface FachbereichQueryProps {
  data: {
    cms: {
      entry: {
        title: string;
        uri: string;
        inhaltstext: string;
        mainImage: [{ url: string }];
        children: [
          {
            title: string;
            uri: string;
            mainImageProjekt: [{ url: string }];
          }
        ];
        // archiv: [{ url: string }];
      };
    };
  };
}

export const fachbereichQuery = graphql`
  # CMS_QueryArgument
  query($uri: String!) {
    cms {
      # add argument
      entry(uri: [$uri]) {
        title
        uri
        ... on CMS_inhalt_fachbereich_Entry {
          inhaltstext
          mainImage {
            url
          }
        }

        children(type: "projekt") {
          title
          uri

          ... on CMS_inhalt_projekt_Entry {
            mainImageProjekt {
              url
            }
          }
        }
      }
    }
  }
`;

const Fachbereich: React.FC<FachbereichQueryProps> = ({ data }) => {
  const entry = data.cms.entry;
  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImage[0].url} />
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <InhaltsText html={entry.inhaltstext} />
      </Wrapper>
      {entry.children.length > 0 ? (
        <>
          <HorizontalWrapper>
            <Title>Referenzprojekte</Title>
          </HorizontalWrapper>
          <GridWrapper>
            <ProjekteGrid>
              {entry.children.slice(0, 9).map((entry) => (
                <ProjektItem
                  itemTitle={entry.title}
                  itemImg={entry.mainImageProjekt[0].url}
                  itemLink={entry.uri}
                  key={entry.uri}
                />
              ))}
            </ProjekteGrid>
            <br />
            <br />
            <br />
            <Button url={"/" + entry.uri + "/archiv"} text="Archiv" />
          </GridWrapper>
        </>
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default Fachbereich;
